import React, { useEffect, useRef, useState } from "react";
import { useSVG } from "./SVGContext";

const FakeScrollbar = ({ horiz }) => {
  const { width, height, viewBox, setViewBox } = useSVG();
  const thumbRef = useRef(null);
  const [thumbLeft, setThumbLeft] = useState(0);

  const onThumbDrag = (e) => {
    const startX = horiz ? e.clientX : e.clientY;
    const startLeft = horiz ? thumbRef.current.offsetLeft : thumbRef.current.offsetTop;

    const onMouseMove = (event) => {
      if (horiz) {
        const deltaX = event.clientX - startX;
        const newLeft = Math.max(0, Math.min(startLeft + deltaX, width - thumbRef.current.offsetWidth));
        setThumbLeft(newLeft);

        const viewBoxX = (newLeft / width) * viewBox.width - width;
        viewBox.x = viewBoxX;
        setViewBox({
          x: viewBoxX,
          ...viewBox,
        });
      } else {
        const deltaY = event.clientY - startX;
        const newTop = Math.max(0, Math.min(startLeft + deltaY, width - thumbRef.current.offsetHeight));
        setThumbLeft(newTop);

        const viewBoxY = (newTop / width) * viewBox.height - width;
        console.log("viewBoxY", viewBoxY);
        viewBox.y = viewBoxY;
        setViewBox({
          y: viewBoxY,
          ...viewBox,
        });
      }
    };

    const onMouseUp = () => {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };

    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseup", onMouseUp);
  };

  const [props, setProps] = useState({});
  useEffect(() => {
    const w = horiz ? "100%" : 20;
    const h = horiz ? 20 : "100%";
    const top = horiz ? 0 : thumbLeft;
    const left = horiz ? thumbLeft : 0;
    const thumbWidth = horiz ? `${(viewBox?.width / width) * 100}%` : "100%";
    const thumbHeight = horiz ? "100%" : `${(viewBox?.height / height) * 100}%`;
    setProps({ w, h, top, left, thumbWidth, thumbHeight });
  }, [width, height, viewBox]);

  const { w, h, top, left, thumbWidth, thumbHeight } = props;
  return (
    <div
      style={{
        position: "absolute",
        width: w,
        height: h,
        background: "#f0f0f0",
        bottom: horiz ? 0 : undefined,
        right: horiz ? undefined : 0,
        top: horiz ? undefined : 0,
        left: horiz ? 0 : undefined,
      }}
    >
      <div
        ref={thumbRef}
        style={{
          position: "absolute",
          top,
          left,
          width: thumbWidth,
          height: thumbHeight,
          background: "#888",
          cursor: "pointer",
        }}
        onMouseDown={onThumbDrag}
      />
    </div>
  );
};

export default FakeScrollbar;
