const canvas = document.createElement("canvas");
const ctx = canvas.getContext("2d");

// Function to parse any valid CSS color format to RGBA
export function parseColor(color) {
  ctx.fillStyle = color;
  const computedColor = ctx.fillStyle;

  if (/^#([0-9A-F]{3}){1,2}$/i.test(computedColor)) {
    return hexToRGBA(computedColor);
  }
  // Extract RGB or RGBA from the computed style
  const rgbaMatch = computedColor.match(/rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d*\.?\d+))?\)/);
  if (rgbaMatch) {
    return {
      r: parseInt(rgbaMatch[1]),
      g: parseInt(rgbaMatch[2]),
      b: parseInt(rgbaMatch[3]),
      a: rgbaMatch[4] !== undefined ? parseFloat(rgbaMatch[4]) : 1, // Default alpha to 1 if not present
    };
  }
  console.log("Invalid color format", color, computedColor);
  return null;
}

// Function to convert hex to RGBA
function hexToRGBA(hex) {
  let r, g, b;

  // Remove the '#' if present
  hex = hex.replace(/^#/, "");

  // If 3-character hex (e.g., #123), expand it to 6 characters (e.g., #112233)
  if (hex.length === 3) {
    r = parseInt(hex[0] + hex[0], 16);
    g = parseInt(hex[1] + hex[1], 16);
    b = parseInt(hex[2] + hex[2], 16);
  } else if (hex.length === 6) {
    r = parseInt(hex.substring(0, 2), 16);
    g = parseInt(hex.substring(2, 4), 16);
    b = parseInt(hex.substring(4, 6), 16);
  }

  return { r, g, b, a: 1 }; // Default alpha to 1 for hex colors
}

// Function to convert RGB(A) to HSL
export function rgbToHSL(r, g, b) {
  r /= 255;
  g /= 255;
  b /= 255;
  const max = Math.max(r, g, b),
    min = Math.min(r, g, b);
  let h,
    s,
    l = (max + min) / 2;

  if (max === min) {
    h = s = 0; // achromatic
  } else {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    h /= 6;
  }
  return [Math.round(h * 360), s, l];
}

// Function to convert HSL to RGB
export function hslToRGB(h, s, l) {
  let r, g, b;

  if (s === 0) {
    r = g = b = l; // achromatic
  } else {
    const hue2rgb = (p, q, t) => {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    };

    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;
    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }

  return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
}

// Function to adjust the hue of an RGBA color and return an RGBA string
function adjustHue(color, deltaHue) {
  const { r, g, b, a } = parseColor(color);
  const [h, s, l] = rgbToHSL(r, g, b);
  const newHue = (h + deltaHue) % 360;
  const [newR, newG, newB] = hslToRGB(newHue / 360, s, l);

  return `rgba(${newR}, ${newG}, ${newB}, ${a})`;
}

// Function to shift hues of a list of colors and return new RGBA colors
export function shiftHues(colorList, hueDelta) {
  // const initialHue = getHue(colorList[0]);
  // const hueDelta = newHueValue - initialHue;

  return colorList.map((color) => adjustHue(color, hueDelta));
}

export function getHue(color) {
  const { r, g, b } = parseColor(color);
  const [hue] = rgbToHSL(r, g, b);
  return hue;
}
