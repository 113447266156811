import { useEffect, useState } from "react";
import { TopBarNumber } from "./TopbarCommons";
import { useSVG } from "../SVGContext";
import AspectRatioIcon from "@mui/icons-material/AspectRatio";
import { IconButton, Menu, MenuItem, Slider, TextField, Tooltip } from "@mui/material";

export function TopbarTextWidthHeight() {
  const [w, setW] = useState(100);
  const [h, setH] = useState(100);
  const [anchorEl, setAnchor] = useState(null);
  const { items, selectedIds, update } = useSVG();

  function updateAll(w, h) {
    for (let id of selectedIds) {
      const item = items[id];
      if (w) item.width = w;
      if (h) item.height = h;
      items[id] = item;
    }
    if (w) setW(w);
    if (h) setH(h);
    update({ items });
  }

  useEffect(() => {
    if (selectedIds.length === 1) {
      setW(items[selectedIds[0]].width);
      setH(items[selectedIds[0]].height);
    } else {
      setW(100);
      setH(100);
    }
  }, [selectedIds]);

  return (
    <>
      <IconButton onClick={(e) => setAnchor(e.currentTarget)}>
        <Tooltip title={"Width & Height"}>
          <AspectRatioIcon />
        </Tooltip>
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchor(null)}>
        {["width", "height"].map((prop) => (
          <MenuItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "5px",
                gap: "10px",
              }}
            >
              <Slider
                style={{ width: "100px" }}
                value={prop === "width" ? w : h}
                min={100}
                max={1000}
                step={50}
                onChange={(e) => {
                  const val = e.target.value;
                  updateAll(prop === "width" ? val : null, prop === "height" ? val : null);
                }}
              />
              <TextField
                type="number"
                value={prop === "width" ? w : h}
                size="small"
                style={{ width: "70px" }}
                onChange={(e) => {
                  const val = e.target.value;
                  updateAll(prop === "width" ? val : null, prop === "height" ? val : null);
                }}
              />
            </div>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
