import { Button, Divider, MenuItem, Select, TextField } from "@mui/material";
import { req } from "../utils/ServerUtils";
import { useSVG } from "../SVGContext";
import { randomColor } from "../LayoutFinder";
import { useState } from "react";

export function LayoutMarkingPanel() {
  const [gap, setGap] = useState(0);
  const [cols, setCols] = useState(1);
  const [bid, setBid] = useState(0);
  const { design, items, selectedIds, update } = useSVG();
  const [spec, setSpec] = useState({ header: {}, footer: {}, blocks: [] });

  function fetchSpec() {
    req("/layout?template_id=" + design.id).then(({ spec }) => {
      onSpecFetched(spec);
    });
  }

  function clearLayout() {
    for (let i in items) {
      if (items[i].spec) {
        delete items[i].spec;
      }
    }
    update({ items });
  }
  function onSpecFetched(spec) {
    function markSpec(id, bid, type, color) {
      if (!items[id]) {
        console.log("Item not found", id);
        return;
      }
      items[id].spec = items[id].spec || {
        bid,
        type,
        color,
      };
    }
    clearLayout();
    setSpec(spec);
    for (let h of ["header", "footer"]) {
      const clr = randomColor();

      for (let field in spec[h].id_spec) {
        const id = spec[h].id_spec[field];
        markSpec(id, h, field, clr);
      }
    }

    for (let i = 0; i < spec["blocks"].length; i++) {
      const block = spec["blocks"][i];
      const clr = randomColor();

      for (let field in block.id_spec) {
        const id = block.id_spec[field];
        markSpec(id, i, field, clr);
      }
    }
    update({ items });
  }
  function finalizeFromView() {
    // you can only make very rudimentary updates to the spec. you can just change the type of each item, that's all
    for (let h of ["header", "footer", "whole-body"]) {
      // just go over all the items and see if they have a spec
      spec[h].id_spec = {};
      for (let id in items) {
        const s = items[id].spec;
        if (s?.bid == h) {
          spec[h].id_spec[s.type] = id;
        }
      }
    }
    for (let i = 0; i < spec["blocks"].length; i++) {
      // just go over all the items and see if they have a spec
      spec["blocks"][i].id_spec = {};
      for (let id in items) {
        const s = items[id].spec;
        if (s?.bid == i) {
          spec["blocks"][i].id_spec[s.type] = id;
        }
      }
    }
    spec.cols = parseInt(cols);
    spec.gap = parseInt(gap);
    setSpec(spec);
    req(
      "/layout",
      "PUT",
      JSON.stringify({
        spec,
        template_id: "" + design.id,
      }),
    ).then((res) => {
      console.log(res);
    });
  }

  function deduceBlock() {
    const params = {
      template_id: "" + design.id,
      block_id: bid,
      block_item_ids: selectedIds,
    };

    req("/deduce_block_spec", "POST", JSON.stringify(params)).then((res) => {
      if (["header", "footer", "whole-body"].includes(bid)) {
        spec[bid] = res.result;
      } else {
        spec.blocks[bid] = res.result;
      }
      setSpec(spec);

      for (let f in res.result.id_spec) {
        items[res.result.id_spec[f]].spec = {
          bid: bid,
          type: f,
          color: randomColor(),
        };
      }
      update({ items });
    });
  }

  function reset() {
    setSpec({ header: {}, footer: {}, blocks: [] });
    for (let id in items) {
      items[id].spec = null;
    }
    update({ items });
  }

  function deduceContainers() {
    // deducing block containers: find min x,y of 0th block
    let x = Infinity,
      y = Infinity,
      maxX = -Infinity,
      maxY = -Infinity;
    for (let id of spec.blocks[0].rendering_order) {
      const item = items[id];
      x = Math.min(x, item["box"]["x"]);
      y = Math.min(y, item["box"]["y"]);
      maxX = Math.max(maxX, item["box"]["x"] + item["box"]["boxWidth"]);
      maxY = Math.max(maxY, item["box"]["y"] + item["box"]["boxHeight"]);
    }
    spec["gap"] = parseInt(gap);
    spec["cols"] = cols;
    spec["containers"] = [
      {
        x,
        y,
        width: maxX - x,
        height: maxY - y,
      },
    ];
    setSpec(spec);
  }

  function finalize() {
    deduceContainers();
    req(
      "/layout",
      "PUT",
      JSON.stringify({
        spec: spec,
        template_id: design.id,
      }),
    ).then((res) => {
      console.log(res);
    });
  }

  function sample() {
    req(
      "/generatev2",
      "POST",
      JSON.stringify({
        subject: "Healthy Living",
        template_id: design.id,
        s3: true,
      }),
    ).then((res) => {
      if (res.design_id) {
        window.open("/design/" + res.design_id, "_blank");
      } else {
        console.log(res);
      }
    });
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        justifyContent: "start",
        gap: 10,
        padding: 20,
      }}
    >
      <TextField label="Gap" value={gap} onChange={(e) => setGap(e.target.value)} />
      <TextField label="Columns" value={cols} onChange={(e) => setCols(e.target.value)} />
      <Select value={bid} onChange={(e) => setBid(e.target.value)} fullWidth>
        <MenuItem value={"header"}>Header</MenuItem>
        <MenuItem value={"0"}>Block 0</MenuItem>
        <MenuItem value={"1"}>Block 1</MenuItem>
        <MenuItem value={"footer"}>Footer</MenuItem>
        <MenuItem value={"whole-body"}>Whole Body</MenuItem>
      </Select>
      <Button
        onClick={deduceBlock}
        variant="contained"
        fullWidth
        style={{
          textTransform: "none",
        }}
      >
        Deduce Spec
      </Button>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: 10,
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Button variant="contained" fullWidth style={{ textTransform: "none" }} onClick={reset}>
          Reset
        </Button>
        <Button
          variant="contained"
          fullWidth
          style={{
            textTransform: "none",
            backgroundColor: "purple",
            color: "white",
          }}
          onClick={finalize}
        >
          Finalize
        </Button>
      </div>
      <Divider />

      <Button variant="contained" fullWidth style={{ textTransform: "none" }} onClick={fetchSpec}>
        Fetch Spec
      </Button>
      <Button variant="contained" fullWidth style={{ textTransform: "none" }} onClick={finalizeFromView}>
        Finalize From View
      </Button>
      <Divider />

      <Button variant="contained" fullWidth style={{ textTransform: "none" }} onClick={sample}>
        Generate Sample
      </Button>
    </div>
  );
}
