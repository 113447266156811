import { Button, IconButton, Slider } from "@mui/material";
import { useState } from "react";
import { useSVG } from "./SVGContext";
import FitScreenIcon from "@mui/icons-material/FitScreen";

export function ZoomSlider({}) {
  const { zoom, zoomLevel, resetZoom, width } = useSVG();

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: 8,
      }}
    >
      <Slider
        size="small"
        min={-150}
        max={-5}
        step={-1}
        value={-zoomLevel}
        onChange={(e) => {
          e.stopPropagation();
          e.preventDefault();

          zoom(null, -e.target.value);
        }}
        style={{
          width: 160,
        }}
      />
      <IconButton onClick={resetZoom}>
        <FitScreenIcon />
      </IconButton>
    </div>
  );
}
