// @ts-nocheck

import { useState } from "react";

function History({ setItems, setGroups }) {
  let [history, setHistory] = useState([]);
  let [poppedList, setPoppedList] = useState([]);

  const add = (params) => {
    console.log("Adding...");
    history = history.slice(-9);
    history = [...history, deepclone(params)];
    setHistory(history);
  };

  const undo = (items) => {
    if (history.length === 0) return;

    let popped = history.pop();
    setHistory([...history]);

    poppedList = [...poppedList, popped];
    setPoppedList(poppedList);

    if (history.length > 0) {
      const state = history[history.length - 1];
      setItems({ ...state.items });
      setGroups({ ...state.groups });
    }
  };

  const redo = (items) => {
    if (poppedList.length === 0) return;

    let popped = poppedList.pop();
    setPoppedList([...poppedList]);

    setHistory([...history, popped]);

    if (popped.items) {
      setItems({ ...popped.items });
    }
  };
  return { add, undo, redo };
}
export default History;

const deepclone = (items) => {
  return JSON.parse(JSON.stringify(items));
};
