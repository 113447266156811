// @ts-nocheck
import { Point2D } from "kld-intersections";
import { DEFTYPES } from "../values/enums";

export const isDev = false; //document.location.href.includes("localhost");

export const dup = (p) => {
  if (!p) return null;
  return { x: p.x, y: p.y };
};
export const dupz = (p) => {
  if (!p) return null;
  if (!p.handles) p.handles = [dup(p), dup(p)];
  return { x: p.x, y: p.y, handles: [dup(p.handles[0]), dup(p.handles[1])] };
};
export const p2D = (p) => new Point2D(p.x, p.y);
export const time = () => new Date().getTime();
export const isSamePoint = (xy, point, threshold = 0.01) => {
  if (!xy || !point) return false;
  return Math.abs(xy.x - point.x) < threshold && Math.abs(xy.y - point.y) < threshold;
};

export const isEqual = (a, b, eps = 0.1) => {
  return Math.abs(a - b) < eps;
};

export const toDegree = (radians) => radians * (180 / Math.PI);
export const toRadians = (degree) => degree * (Math.PI / 180);
export const normalize = (radians) => {
  while (radians < 0) radians += 2 * Math.PI;
  while (radians >= 2 * Math.PI) radians -= 2 * Math.PI;
  return radians;
};
export const pointAt = (p, dist, angle) => {
  return { x: p.x + dist * Math.cos(angle), y: p.y + dist * Math.sin(angle) };
};
export const randBet = (a, b) => a + Math.random() * (b - a);

export function rand() {
  // Generating a secure random number between 0 and 1
  var array = new Uint32Array(1);
  window.crypto.getRandomValues(array);
  return Math.floor((array[0] / (0xffffffff + 1)) * 1000000000);
}

export const D = (pt1, pt2) => Math.sqrt((pt1.x - pt2.x) ** 2 + (pt1.y - pt2.y) ** 2);

export const sq = (a) => a * a;

export const ppt = (p) => p.x.toFixed(0) + "," + p.y.toFixed(0);
export const pptz = (p) => ppt(p) + `(${ppt(p.handles[0])}, ${ppt(p.handles[1])})`;

const colors = ["#e61919", "#192ee6", "#e69419", "#e61994", "#19e5e6", "#bde619", "#7f19e6", "#19e62e", "#19a8e6", "#80e619", "#19e66b", "#bd19e6", "#e619d1", "#42e619", "#4219e6", "#196be6", "#e6d119", "#e61957", "#e65719", "#19e6a8"];
window.lastColorIdx = 0;
export function generateRandomColor() {
  window.lastColorIdx = (window.lastColorIdx + 1) % colors.length;
  return colors[window.lastColorIdx];
}

export function calculatePointSign(point, line) {
  const side = (point.x - line.p1.x) * (line.p2.y - line.p1.y) - (point.y - line.p1.y) * (line.p2.x - line.p1.x);
  return Math.sign(side);
}

function getClientCoords(event, svgRef) {
  let x, y;
  if (event.touches) {
    let touch = event.touches[0];
    if (!touch) {
      return { x: 0, y: 0 };
    }
    x = touch.clientX - svgRef.current.getBoundingClientRect().left;
    y = touch.clientY - svgRef.current.getBoundingClientRect().top;
  } else if (event.nativeEvent && event.nativeEvent.offsetX != undefined) {
    x = event.nativeEvent.offsetX;
    y = event.nativeEvent.offsetY;
  } else {
    x = event.clientX;
    y = event.clientY;
  }

  return { x, y };
}

export const getZoomLevel = (svgRef) => {
  if (!svgRef.current) return { x: 0, y: 0 };

  // GOTCHA: we use the first child of the first child of the svg here
  const rect = svgRef.current.children[0].children[0];
  const wUnits = rect.getAttribute("width");
  if (!wUnits) return 1;

  const rectBox = rect.getBoundingClientRect();
  const wPx = rectBox.width;
  const wUnitsPerPixel = wUnits / wPx;
  return wUnitsPerPixel;
};

export const getCoordinatesInSVG = (svgRef, viewBox, width, height, event) => {
  if (!svgRef.current) return { x: 0, y: 0 };
  let { x, y } = getClientCoords(event, svgRef); // these x and y are pixel positions within svg
  return getCoordsInSvg(svgRef, { x: event.clientX, y: event.clientY });
};

export const convertSvgCoordsToAbsolute = (svgRef, xy) => {
  // Get the inverse of the current transformation matrix of the SVG
  const inverseMatrix = svgRef.current.getScreenCTM().inverse();
  var pt = svgRef.current.createSVGPoint();
  pt.x = 0;
  pt.y = 0;
  // Apply the inverse transformation to origin
  const originDocumentCoordinates = pt.matrixTransform(inverseMatrix);
  return {
    x: xy.x + Math.abs(originDocumentCoordinates.x), // for some reason, originDocumentCoordinates has negative values
    y: xy.y + Math.abs(originDocumentCoordinates.y),
  };
};
export function distanceToLine(point, line) {
  // Calculate the length of the line segment
  const lengthSquared = Math.pow(line.p2.x - line.p1.x, 2) + Math.pow(line.p2.y - line.p1.y, 2);
  if (lengthSquared === 0) {
    // If the line segment is just a point, return the distance to that point
    return Math.sqrt(Math.pow(point.x - line.p1.x, 2) + Math.pow(point.y - line.p1.y, 2));
  }

  // Calculate the t value (projection factor) for the point onto the line segment
  const t = ((point.x - line.p1.x) * (line.p2.x - line.p1.x) + (point.y - line.p1.y) * (line.p2.y - line.p1.y)) / lengthSquared;

  // Clamp t to the interval [0, 1] to ensure the projection of the point onto the line segment lies within the segment
  const tClamped = Math.max(0, Math.min(1, t));

  // Calculate the coordinates of the closest point on the line segment to the given point
  const closestX = line.p1.x + tClamped * (line.p2.x - line.p1.x);
  const closestY = line.p1.y + tClamped * (line.p2.y - line.p1.y);

  // Calculate the distance between the given point and the closest point on the line segment
  const distance = Math.sqrt(Math.pow(point.x - closestX, 2) + Math.pow(point.y - closestY, 2));
  return distance;
}

export const getCoordsWithinTarget = (coordsInSvg, target, svgRef) => {
  let elemRect = target.getBoundingClientRect();
  let rect = svgRef.current.getBoundingClientRect();
  var xx = elemRect.left - (rect.left + window.scrollX),
    yy = elemRect.top - (rect.top + window.scrollY);
  let targetXY = { x: xx, y: yy };

  const relativeX = coordsInSvg.x - targetXY.x;
  const relativeY = coordsInSvg.y - targetXY.y;
  return { x: relativeX, y: relativeY };
};

export function pointsToRectPath(x, y, width, height, cornerRadius) {
  // Make sure the corner radius is within bounds
  cornerRadius = Math.min(cornerRadius, Math.min(width, height) / 2);

  // Construct the path string
  const path = `M${x},${y + cornerRadius}
                  A${cornerRadius},${cornerRadius} 0 0 1 ${x + cornerRadius},${y}
                  L${x + width - cornerRadius},${y}
                  A${cornerRadius},${cornerRadius} 0 0 1 ${x + width},${y + cornerRadius}
                  L${x + width},${y + height - cornerRadius}
                  A${cornerRadius},${cornerRadius} 0 0 1 ${x + width - cornerRadius},${y + height}
                  L${x + cornerRadius},${y + height}
                  A${cornerRadius},${cornerRadius} 0 0 1 ${x},${y + height - cornerRadius}
                  Z`;

  return path.replace(/\s+/g, " "); // Remove extra whitespace
}

export function pointsToPath(points, isClosed = true) {
  let path = "M" + points[0].x + "," + points[0].y + " ";
  for (let i = 1; i < points.length; i += 1) {
    path += "L" + points[i].x + "," + points[i].y + " ";
  }
  if (isClosed) path += "Z";
  return path;
}
function dedup(a, equals) {
  let deduped = [];
  for (let i = 0; i < a.length; i++) {
    let found = false;
    for (let j = 0; j < deduped.length; j++) {
      if (equals(a[i], deduped[j])) {
        found = true;
        break;
      }
    }
    if (!found) {
      deduped.push(a[i]);
    }
  }
  return deduped;
}
export function dedupFloats(floats) {
  return dedup(floats, (a, b) => Math.abs(a - b) < 1e-5);
}
export function dedupPoints(points, cmp = isSamePoint) {
  return dedup(points, cmp);
}
export function getCoordsInSvg(svgRef, xy) {
  const svgPoint = svgRef.current.createSVGPoint();
  svgPoint.x = xy.x;
  svgPoint.y = xy.y;
  const svgCoord = svgPoint.matrixTransform(svgRef.current.getScreenCTM().inverse());
  return { x: svgCoord.x, y: svgCoord.y };
}
export function getSVGBoundingBox(svgRef, domElement) {
  if (!domElement) return null;
  const box = domElement.getBoundingClientRect();
  const p1 = getCoordsInSvg(svgRef, { x: box.left, y: box.top });
  const p2 = getCoordsInSvg(svgRef, { x: box.right, y: box.bottom });

  return {
    x: p1.x - 1,
    y: p1.y - 1,
    width: p2.x - p1.x + 2,
    height: p2.y - p1.y + 2,
  };
}

export function getBoundingBox(svgRef, item) {
  let svgElement = document.getElementById(item.id);
  if (svgElement == null) return null; // this happens when I start drawing bezier curve (clicking 1st point)

  // GOTCHA: if the item is a text path, then get the path's bounding box
  if (item.pathId) {
    svgElement = document.getElementById(item.pathId);
  }
  const box = svgElement.getBoundingClientRect();

  const p1 = getCoordsInSvg(svgRef, { x: box.left, y: box.top });
  const p2 = getCoordsInSvg(svgRef, { x: box.right, y: box.bottom });

  return {
    x: p1.x - 1,
    y: p1.y - 1,
    width: p2.x - p1.x + 2,
    height: p2.y - p1.y + 2,
  };
}

export function updateBoxes(svgRef, items, idx) {
  for (let ii = 0; ii < idx.length; ii++) {
    const i = idx[ii];
    if (!items[i]) continue; // temporary fix, somehow idx contains "root", have to figure out how
    items[i].box = getBoundingBox(svgRef, items[i]);
  }

  return items;
}
export function deleteShapeAttrs(item) {
  for (let a of ["width", "height", "shapeType", "sides", "innerRadius", "outerRadius"]) {
    delete item[a];
  }
  return item;
}

export function flip(item, center, horiz, vert) {
  if (!item.points) return item;
  item.points = item.points.map((p) => flipBezierPoint(p, center, horiz, vert));
  return item;
}
export function flipBezierPoint(p, center, horiz, vert) {
  if (horiz) {
    p.x = center.x + (center.x - p.x);
    p.handles[0].x = center.x + (center.x - p.handles[0].x);
    p.handles[1].x = center.x + (center.x - p.handles[1].x);
  }
  if (vert) {
    p.y = center.y + (center.y - p.y);
    p.handles[0].y = center.y + (center.y - p.handles[0].y);
    p.handles[1].y = center.y + (center.y - p.handles[1].y);
  }
  return p;
}

// replace url(, ), all occurrences of all quotes and #
export const fillToFillId = (fill) => {
  return fill?.replace("url(", "").replace(")", "").replace(/['"]+/g, "").replace("#", "");
};

export const fillDef = (item, defs) => {
  const fillId = fillToFillId(item.fill);
  return defs.find((def) => def.id == fillId);
};

export const camel = (s) => {
  return s.replace(/-([a-z])/g, (g) => g[1].toUpperCase());
};

export const cap = (s) => {
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const addFontDef = (font, defs) => {
  font = font.replace(/ /g, "+");

  const url = `https://fonts.googleapis.com/css2?family=${font}&display=swap`;
  const defId = defs.find((def) => def.type == DEFTYPES.IMPORTURL && def.url === url);
  if (!defId) {
    defs.push({
      type: DEFTYPES.IMPORTURL,
      url: url,
    });
  }
  return defs;
};

export const getTemplatePreviewUrl = (templateId) => {
  if (!templateId) return "";
  templateId = templateId + "";

  if (!templateId.endsWith(".jpg")) templateId += ".png";
  return `https://movingvectors.s3.amazonaws.com/template-previews/${templateId}`;
};

export const isNumber = (n) => {
  return !isNaN(parseFloat(n)) && isFinite(n);
};

export function getAllFontsFromProseMirrorData(dataArray) {
  const fonts = new Set();

  dataArray.forEach((data) => {
    function extractFonts(node) {
      if (node.marks) {
        node.marks.forEach((mark) => {
          if (mark.type === "textStyle" && mark.attrs && mark.attrs.fontFamily) {
            fonts.add(mark.attrs.fontFamily);
          }
        });
      }
      if (node.content) {
        node.content.forEach(extractFonts);
      }
    }
    if (data.content) {
      data.content.forEach(extractFonts);
    }
  });

  return Array.from(fonts);
}
