import { generateHTML } from "@tiptap/core";
import { EditorExtensions } from "./views/Tiptap";

export function extractTextColorFromPM(node) {
  for (let mark of node.marks || []) {
    if (mark.attrs?.color) {
      return mark.attrs.color;
    }
  }

  if (node.content && Array.isArray(node.content)) {
    let clr;
    node.content.forEach((childNode) => {
      clr = clr || extractTextColorFromPM(childNode);
    });
    return clr;
  }
}

export function setTextColorInPM(node, color) {
  for (let mark of node.marks || []) {
    if (mark.attrs?.color) {
      mark.attrs.color = color;
      return;
    }
  }
  if (node.content && Array.isArray(node.content)) {
    node.content.forEach((childNode) => {
      setTextColorInPM(childNode, color);
    });
  }
}

export function extractFontSizeFromPM(node) {
  if (!node) return;

  if (node.attrs?.fontSize) return +node.attrs.fontSize.replace("px", "").replace("pt", "");

  for (let mark of node.marks || []) {
    if (mark.attrs?.fontSize) return +mark.attrs.fontSize.replace("px", "").replace("pt", "");
  }

  if (node.content && Array.isArray(node.content)) {
    let fs;
    node.content.forEach((childNode) => {
      fs = fs || extractFontSizeFromPM(childNode);
    });
    return fs;
  }
}
export function setFontSizeInPM(node, value, absoluteOrRelative) {
  if (node.attrs?.fontSize) {
    if (absoluteOrRelative == "relative") node.attrs.fontSize = addToFontSize(node.attrs.fontSize, value);
    else node.attrs.fontSize = value + "px";
  }
  if (node.marks) {
    for (let mark of node.marks) {
      if (mark.attrs?.fontSize) {
        if (absoluteOrRelative == "relative") mark.attrs.fontSize = addToFontSize(mark.attrs.fontSize, value);
        else mark.attrs.fontSize = value + "px";
      }
    }
  }
  node.content?.forEach((child) => {
    setFontSizeInPM(child, value, absoluteOrRelative);
  });
}

function addToFontSize(fontSizeStr, num) {
  let px = "";
  if (fontSizeStr.includes("px")) {
    px = "px";
  }
  if (fontSizeStr.includes("pt")) {
    px = "pt";
  }
  return +fontSizeStr.replace(px, "") + num + px;
}

export function updateSelectedTextItems({ selectedIds, items, update }, updateItemFn) {
  for (let id in selectedIds) {
    const item = items[selectedIds[id]];
    if (item.proseMirrorData) {
      updateItemFn(item);
      item.html = generateHTML(item.proseMirrorData, EditorExtensions);
      items[selectedIds[id]] = item;
    }
  }
  update({ items });
}

export function extractLineHeightFromPM(node) {
  if (node.attrs?.lineHeight) return +node.attrs.lineHeight;

  if (node.content && Array.isArray(node.content)) {
    let lh;
    node.content.forEach((childNode) => {
      lh = lh || extractLineHeightFromPM(childNode);
    });
    return lh;
  }
}
export function setLineHeightInPM(node, value) {
  if (node.attrs) node.attrs.lineHeight = value;
  if (node.content) {
    node.content.forEach((child) => {
      setLineHeightInPM(child, value);
    });
  }
}
