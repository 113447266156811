// @ts-nocheck
import { useState } from "react";
import { useSVG } from "./SVGContext";
import { Cursor } from "./values/enums";
import { getBoundingBox, isSamePoint, updateBoxes } from "./utils";
import Smartguide from "./Smartguide";
import { transform } from "./utils/transformUtils";
import { duplicate } from "./utils/ClipboardUtils";
import { D, getSVGBoundingBox, normalize, toDegree, toRadians } from "./utils/utils";
import { PointsOverlays } from "../components/PointsOverlays";
import { findAncestralTransform, getPoints, getRotateCursor, getUnmovingPoint, isBottomRightOfSomeBoundingBox } from "./utils/PointerUtils";
import { snapMouse } from "./utils/SnapUtils";

function usePointer(provider) {
  const { setOutline } = provider;
  let [dragging, setDragging] = useState(null);
  let [mouseDownPoint, setMouseDownPoint] = useState({ x: 0, y: 0 });
  let [mouseMovePoint, setMouseMovePoint] = useState({ x: 0, y: 0 });
  let [mouseWasDown, setMouseWasDownInternal] = useState(false);

  const setMouseWasDown = (v) => {
    mouseWasDown = v;
    setMouseWasDownInternal(v);
  };
  const onToolUnselect = () => {
    setOutline(null);
  };
  const onMouseDownNew = ({ event, xy, target, items, groups, selectedIds, z }) => {

    setMouseWasDown(true);
    if (z.isPointerOrPan == "pan") {
      dragging = "moveBoard";
    } else {
      if (selectedIds.length == 0 || Array.from(target?.classList).includes("Board") || target?.getAttribute("type") == "boundary-rect" || target?.getAttribute("id") == "background") {
        dragging = "drawBox";
      } else {
        dragging = "translate";
      }
    }
    setDragging(dragging);

    mouseDownPoint = xy;
    setMouseDownPoint(xy);

    mouseMovePoint = xy;
    setMouseMovePoint(xy);

    z.multiSelectBox = null;
    z.setMultiSelectBox(null);
  };

  const onMouseMoveFree = ({ event, xy, target, z }) => {
    const { svgRef, setOutline, setMultiSelectBox, setSelectedIds, setReplacingItemId, setViewBox } = z;

    if (z.isPointerOrPan == "pan") return;

    // this is mousemove without any clicking
    let itemId;
    while (target) {
      itemId = target.getAttribute("id");
      if (itemId && itemId != "background") break;
      target = target.parentElement;
    }
    if (itemId == z.outline?.id) return;
    const id = target.getAttribute("id");
    if (!id || id == "background" || !z.items[id]) {
      setOutline(null);
      return;
    }

    let box = getSVGBoundingBox(svgRef, target);
    z.outline = {
      d: `M${box.x} ${box.y} h${box.width} v${box.height} h${-box.width} Z`,
      stroke: "blue",
      id: id,
    };
    setOutline(z.outline);
  };

  const onMouseMoveNew = ({ event, xy, target, items, groups, defs, selectedIds, cursor, multiSelectBox, z }) => {
    const { svgRef, setOutline, setMultiSelectBox, setSelectedIds, setReplacingItemId, setViewBox } = z;

    if (!mouseWasDown) {
      onMouseMoveFree({ event, xy, target, z });
      return;
    }
    if (dragging == "drawBox") {
      multiSelectBox = {
        x: Math.min(mouseDownPoint.x, xy.x),
        y: Math.min(mouseDownPoint.y, xy.y),
        width: Math.abs(xy.x - mouseDownPoint.x),
        height: Math.abs(xy.y - mouseDownPoint.y),
        points: [],
      };
      setMultiSelectBox(multiSelectBox);

      setOutline(null);

      mouseMovePoint = xy;
      setMouseMovePoint(xy);
      return;
    } else if (dragging) {
      if (dragging == "moveBoard") {
        const dirX = xy.x - mouseMovePoint.x < 0 ? 1 : -1;
        const dirY = xy.y - mouseMovePoint.y < 0 ? 1 : -1;
        setViewBox({
          x: z.viewBox.x + dirX * Math.abs(event.movementX || xy.x - mouseMovePoint.x),
          y: z.viewBox.y + dirY * Math.abs(event.movementY || xy.y - mouseMovePoint.y),
          width: z.viewBox.width,
          height: z.viewBox.height,
        });

        // event.preventDefault();
        // event.stopPropagation();
      } else {
        snapMouse(event, xy, z);
        for (let i = 0; i < selectedIds.length; i++) {
          let selectedId = selectedIds[i];
          let item = items[selectedId];
          if (!item || item.locked) continue;

          if (dragging == "translate") {
            const transformation = {
              type: "translate",
              x: xy.x - mouseMovePoint.x,
              y: xy.y - mouseMovePoint.y,
            };
            item = transform(svgRef, item, transformation);
            items[item.id] = item;
          }
        }
      }

      z.update({ items, groups, defs, selectedIds, changed_item_ids: selectedIds }, false);
      setOutline(null);

      multiSelectBox = null;
      setMultiSelectBox(null);

      mouseMovePoint = xy;
      setMouseMovePoint(xy);
    }
  };

  const onMouseUpNew = ({ event, xy, target, items, groups, defs, selectedIds, cursor, multiSelectBox, z }) => {
    const { svgRef, setOutline, setMultiSelectBox, setSelectedIds, setReplacingItemId, setViewBox } = z;

    if (!mouseWasDown) return;
    if (z.outline) {
      if (event.ctrlKey || event.shiftKey) {
        if (selectedIds.includes(z.outline.id)) {
          setSelectedIds(selectedIds.filter((id) => id != z.outline.id));
        } else {
          setSelectedIds([...selectedIds, z.outline.id]);
        }
      } else {
        setSelectedIds([z.outline.id]);
      }
    } else if (dragging == "drawBox" && multiSelectBox) {
      multiSelectBox.points = getPoints(multiSelectBox);
      setMultiSelectBox({ ...multiSelectBox });
      // mark every item that is inside the box as selected
      for (let id in items) {
        if (id == "background") continue;

        let item = items[id];
        let box = z.getBox(item.id);
        // if any part of   the box is inside the multiSelectBox
        if (box && box.x + box.width > multiSelectBox.x && box.x < multiSelectBox.x + multiSelectBox.width && box.y + box.height > multiSelectBox.y && box.y < multiSelectBox.y + multiSelectBox.height) {
          if (!selectedIds.includes(id)) {
            selectedIds = [...selectedIds, id];
          }
        } else {
          selectedIds = selectedIds.filter((i) => i != id);
        }
      }
      setSelectedIds(selectedIds);
    } else if (dragging == "translate" && D(mouseDownPoint, xy) > 1) {
      items = updateBoxes(svgRef, items, selectedIds);
      z.update({ items, groups, defs, selectedIds, changed_item_ids: selectedIds });
    } else {
      setSelectedIds([]);
    }

    // clear replacing icon (turned on when an svg is clicked and "replace" button is clicked)
    setReplacingItemId(null);

    dragging = null;
    setDragging(dragging);

    setMouseWasDown(false);
  };
  return {
    onMouseMoveNew,
    onMouseDownNew,
    onMouseUpNew,
    onToolUnselect,
    isPointer: true,
    name: "pointer",
  };
}

export default usePointer;
