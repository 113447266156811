import { useEffect } from "react";
import { useSVG } from "../SVGContext";
import { EditorContent } from "@tiptap/react";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { IconButton } from "@mui/material";
import { EditorExtensions } from "./Tiptap";
import { generateHTML } from "@tiptap/react";

export function TextView({ item, overlay }) {
  const { editor, editingId, setEditingId, update, items, selectedIds, getBox } = useSVG();

  useEffect(() => {
    if (overlay && item) {
      const div = document.getElementById(item.id)?.firstChild;
      const h = div?.clientHeight, w = div?.clientWidth;
      if (Math.abs(item.height - h) < 5 && Math.abs(item.width - w) < 5) return;
      item.height = h;
      item.width = w;
      update({ items });
    }
  }, [overlay, item])

  // the way editing works is this:
  // in SVGContext, there is one instance of editor (hook). I set editor content whenever selected item changes. Then here, i'm including editor view only if the item is selected, and only for overlay
  // additionally: text html is generated whenver page is set. this is unnecessary if you can do it in the server once.
  // it is also generated whenever editor content changes.

  // if (overlay && editingId != item.id) return null;

  function next(e, d) {
    e.stopPropagation();
    e.preventDefault();

    item.selected_text_option = item.selected_text_option || 0;
    item.selected_text_option = (item.selected_text_option + d + item["text-options"].length) % item["text-options"].length;
    try {
      if (item.proseMirrorData && item.proseMirrorData.content[0].content[0].text) {
        item.proseMirrorData.content[0].content[0].text = item["text-options"][item.selected_text_option];
        item.html = generateHTML(item.proseMirrorData, EditorExtensions);
        update({ items });
      }
    } catch (e) {
      console.error(e);
    }
  }

  if (item.proseMirrorData) {
    return (
      <>
        {/* has an overflow visible in css */}
        <foreignObject
          x={item.x}
          y={item.y}
          width={item.width}
          height={item.height}
          transform={item.transform}
          className="text-foreign-object"
          onDoubleClick={() => {
            setEditingId(item.id);
            editor?.commands.setContent(item.proseMirrorData);
            editor?.commands.focus();
          }}
        >
          <div id={item.id} xmlns="http://www.w3.org/1999/xhtml" style={{ width: "100%", height: "100%" }}>
            {editingId == item.id && overlay && <EditorContent editor={editor} />}
            {editingId != item.id && <div dangerouslySetInnerHTML={{ __html: item.html }} />}
          </div>
        </foreignObject>
        {overlay && item["text-options"] && selectedIds.length == 1 && (
          <foreignObject type="overlay" x={item.x} y={item.y + item.height + 10} width={item.width} height={30} transform={item.transform}>
            <div
              id={item.id}
              xmlns="http://www.w3.org/1999/xhtml"
              style={{
                width: "100px",
                height: "100%",
                background: "white",
                border: "1px solid black",
                borderRadius: "5px",

                display: "flex",
                flexDirection: "row",
                gap: 10,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <IconButton onClick={(e) => next(e, -1)}>
                <ArrowLeftIcon />
              </IconButton>
              <IconButton onClick={(e) => next(e, 1)}>
                <ArrowRightIcon />
              </IconButton>{" "}
            </div>
          </foreignObject>
        )}
      </>
    );
  }
}
