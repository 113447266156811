import React, { useEffect, useState } from "react";
import { Autocomplete, Button, ClickAwayListener, IconButton, InputAdornment, Link, Menu, MenuItem, Popper, Select, TextField } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import SearchIcon from "@mui/icons-material/Search";
import { useSVG } from "./SVGContext";
import AspectRatio from "@mui/joy/AspectRatio";

const ResizeMenu = ({ resizeItems, buttonLabel }) => {
  const { items, groups, defs, selectedIds, width, height, resize } = useSVG();

  const [anchorEl1, setAnchorEl1] = useState(null);
  // const [selectedResizeOption, setSelectedResizeOption] = useState(0);

  // useEffect(() => {
  //   const idx = resizeItems.findIndex((it) => it.w == width && it.h == height);
  //   if (idx >= 0) {
  //     setSelectedResizeOption(idx);
  //   } else {
  //     setSelectedResizeOption(0);
  //   }
  // }, [resizeItems]);

  // useEffect(() => {
  //   if (selectedResizeOption === 0) {
  //   } else {
  //     resize(resizeItems[selectedResizeOption].w, resizeItems[selectedResizeOption].h);
  //   }
  // }, [selectedResizeOption]);

  const openMenu1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };

  const closeMenu1 = () => {
    setAnchorEl1(null);
  };

  return (
    <div>
      <Button
        size="small"
        style={{
          textTransform: "none",
        }}
        variant="outlined"
        onClick={openMenu1}
      >
        {buttonLabel}
      </Button>
      <ClickAwayListener onClickAway={(e) => e.type === "click" && closeMenu1}>
        <Menu open={Boolean(anchorEl1)} anchorEl={anchorEl1} onClose={closeMenu1} TransitionProps={{ timeout: 0 }}>
          <div
            style={{
              width: 300,
              padding: 20,

              display: "flex",
              flexDirection: "column",
              gap: 20,
              justifyContent: "start",
              alignItems: "stretch",
            }}
          >
            <SimpleResize
              onCustomChange={() => {
                // setSelectedResizeOption(0);
              }}
              resize={resize}
            />
            {/* 
            <div>
              <Select
                value={selectedResizeOption}
                onChange={(e) => {
                  setSelectedResizeOption(e.target.value);
                }}
                style={{
                  width: "100%",
                }}
                renderValue={(selected) => (
                  <div
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {resizeItems[selected].label}
                  </div>
                )}
              >
                {resizeItems.map((item, i) => (
                  <MenuItem value={i}>
                    <ResizeMenuItemLabel item={item} />
                  </MenuItem>
                ))}
              </Select>
            </div> */}
          </div>
        </Menu>
      </ClickAwayListener>
    </div>
  );
};

function ResizeMenuItemLabel({ item }) {
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>{item.label}</div>
      </div>
      {item.h && (
        <div
          style={{
            fontSize: "0.7rem",
            color: "gray",
          }}
        >
          {item.w} x {item.h}
        </div>
      )}
    </div>
  );
}
export default ResizeMenu;

function SimpleResize({ onCustomChange, resize }) {
  const { width, height, changeDimensions } = useSVG();
  const [w, setW] = useState(width);
  const [h, setH] = useState(height);

  useEffect(() => {
    setW(width);
    setH(height);
  }, [width, height]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "start",
        alignItems: "center",
        gap: 10,
        borderRadius: 5,
      }}
    >
      <TextField
        label="Width"
        type="number"
        size="small"
        value={w}
        onChange={(e) => {
          setW(e.target.value);
        }}
        onMouseUp={(e) => {
          resize(e.target.value, height);
          onCustomChange();
        }}
      />
      <span>x</span>
      <TextField
        label="Height"
        type="number"
        size="small"
        value={h}
        onChange={(e) => {
          setH(e.target.value);
        }}
        onMouseUp={(e) => {
          resize(width, e.target.value);
          onCustomChange();
        }}
      />
      <span>px</span>
    </div>
  );
}
