import { useEffect, useState } from "react";
import { useSVG } from "../SVGContext";
import { updateAllSelectedTexts } from "./TopbarText";
import { Button, ButtonGroup, TextField } from "@mui/material";
import { generateHTML } from "@tiptap/core";
import { EditorExtensions } from "../views/Tiptap";
import { extractFontSizeFromPM, setFontSizeInPM, updateSelectedTextItems } from "../ProseUtils";

export function TopbarFontSize() {
  const [showTextField, setShowTextField] = useState(false);
  const { items, update, selectedIds, editorState, editor } = useSVG();

  function changeFontSize(size, itemUpdateFn) {
    if (isNaN(size)) return;

    if (!editor.isFocused) {
      updateSelectedTextItems({ selectedIds, items, update }, itemUpdateFn);
      setShowTextField(false);
    } else {
      editor
        .chain()
        .focus()
        .setFontSize(size + "px")
        .run();
      editor.view.dispatch(editor.state.tr);
    }
  }
  return (
    <>
      <FontSizePlusMinus showTextField={showTextField} setShowTextField={setShowTextField} changeFontSize={changeFontSize} />
      <FontSizeAbsolute showTextField={showTextField} setShowTextField={setShowTextField} changeFontSize={changeFontSize} />
    </>
  );
}
function FontSizePlusMinus({ showTextField, setShowTextField, changeFontSize }) {
  if (showTextField) return null;
  const [fontSizeNum, setFontSizeNum] = useState(12);
  const { items, update, selectedIds, editorState, editor } = useSVG();

  function setFontSize(size, sign) {
    changeFontSize(size, (it) => setFontSizeInPM(it.proseMirrorData, sign, "relative"));
  }

  useEffect(() => {
    setFontSizeNum(whichFontSizeToShow(editor, editorState, items, selectedIds));
  }, [selectedIds, editorState, items]);

  return (
    <ButtonGroup>
      <Button onClick={() => setFontSize(fontSizeNum - 1, -1)}>
        <span>-</span>
      </Button>
      <Button onClick={() => setShowTextField(true)}>{fontSizeNum}</Button>
      <Button onClick={() => setFontSize(fontSizeNum + 1, 1)}>
        <span>+</span>
      </Button>
    </ButtonGroup>
  );
}

function FontSizeAbsolute({ showTextField, setShowTextField, changeFontSize }) {
  const { update, items, selectedIds, editorState, editor } = useSVG();
  const [fontSize, setFontSize] = useState(12);

  useEffect(() => {
    setFontSize(whichFontSizeToShow(editor, editorState, items, selectedIds));
  }, [selectedIds, editorState, items]);

  return (
    <div
      style={{
        display: showTextField ? "flex" : "none",
        // width:0,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "start",
      }}
    >
      <TextField
        label="Size"
        type="number"
        size="small"
        value={fontSize}
        onChange={(e) => setFontSize(+e.target.value)}
        onBlur={(e) => {
          let value = +e.target.value;
          changeFontSize(value, (it) => setFontSizeInPM(it.proseMirrorData, value, "absolute"));
        }}
        style={{ display: showTextField ? "block" : "none", width: 60 }}
      />
    </div>
  );
}

function whichFontSizeToShow(editor, editorState, items, selectedIds) {
  if (editor.isFocused) {
    return editorState.fontSize.replace ? +editorState.fontSize.replace("px", "") : +editorState.fontSize;
  } else if (selectedIds.length > 1) {
    return " ";
  } else if (selectedIds.length === 1) {
    return extractFontSizeFromPM(items[selectedIds[0]].proseMirrorData);
  }
}
