import { useState } from "react";
import { useSVG } from "./SVGContext";
import { req, updateDesignServer } from "./utils/ServerUtils";
import { Button, Dialog, DialogContent, Divider, Menu, MenuItem, TextField } from "@mui/material";
import { ROOT_GROUP_ID } from "./values/constants";
import { attachBoxes } from "./EditorNavBar";

export function LayoutFinder({}) {
  const { userInfo, design, items, groups, selectedIds, update, setLeftPanel } = useSVG();
  let [fetchedSpec, setFetchedSpec] = useState(null);
  const [anchorEl, setAnchor] = useState(null);
  const [showLayoutSettings, setShowLayoutSettings] = useState(false);
  const [layoutProps, setLayoutProps] = useState({});

  if (!design?.isTemplate || !userInfo?.permissions.includes("can_ai_generate")) {
    return null;
  }

  function clearLayout() {
    for (let i in items) {
      if (items[i].spec) {
        delete items[i].spec;
      }
    }
    update({ items });
  }

  function updateLayout() {
    if (!fetchedSpec) {
      console.error("No fetched spec");
      return;
    }
    // you can only make very rudimentary updates to the spec. you can just change the type of each item, that's all
    for (let h of ["header", "footer"]) {
      // just go over all the items and see if they have a spec
      fetchedSpec[h].id_spec = {};
      for (let id in items) {
        const s = items[id].spec;
        if (s?.bid == h) {
          fetchedSpec[h].id_spec[s.type] = id;
        }
      }
    }
    for (let i = 0; i < fetchedSpec["blocks"].length; i++) {
      // just go over all the items and see if they have a spec
      fetchedSpec["blocks"][i].id_spec = {};
      for (let id in items) {
        const s = items[id].spec;
        if (s?.bid == i) {
          fetchedSpec["blocks"][i].id_spec[s.type] = id;
        }
      }
    }
    setFetchedSpec(fetchedSpec);
    req(
      "/layout",
      "PUT",
      JSON.stringify({
        spec: fetchedSpec,
        template_id: design.id,
      }),
    ).then((res) => {
      console.log(res);
    });
  }

  function onSpecFetched(spec) {
    function markSpec(id, bid, type, color) {
      if (!items[id]) {
        console.log("Item not found", id);
        return;
      }
      items[id].spec = items[id].spec || {
        bid,
        type,
        color,
      };
    }
    clearLayout();
    setFetchedSpec(spec);
    for (let h of ["header", "footer"]) {
      const clr = randomColor();

      for (let field in spec[h].id_spec) {
        const id = spec[h].id_spec[field];
        markSpec(id, h, field, clr);
      }
    }

    for (let i = 0; i < spec["blocks"].length; i++) {
      const block = spec["blocks"][i];
      const clr = randomColor();

      for (let field in block.id_spec) {
        const id = block.id_spec[field];
        markSpec(id, i, field, clr);
      }
    }
    update({ items });
  }
  function getLayout() {
    req("/layout?template_id=" + design.id).then(({ spec }) => {
      onSpecFetched(spec);
    });
  }

  function findLayout() {
    req(
      "/deduce_layout",
      "POST",
      JSON.stringify({
        template_id: design.id,
        layout_props: layoutProps,
      }),
    ).then(({ spec }) => {
      onSpecFetched(spec);
    });
  }

  function createSample() {}

  function markBlock() {
    const params = {
      template_id: design.id,
      block_id: "0",
      block_item_ids: selectedIds,
    };
    req("/deduce_block_spec", "POST", JSON.stringify(params)).then((res) => {
      for (let f in res.result.id_spec) {
        items[res.result.id_spec[f]].spec = {
          bid: "0",
          type: f,
          color: randomColor(),
        };
      }
      update({ items });
    });
  }

  return (
    <>
      <Button
        onClick={(e) => {
          setLeftPanel("layout-helper");
          // setAnchor(anchorEl ? null : e.currentTarget);
        }}
        size="small"
        variant="contained"
        className="navbar-btn"
      >
        Layout Helper
      </Button>

      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchor(null)}>
        {/* <MenuItem onClick={getLayout}>Fetch Layout</MenuItem> */}
        {/* <MenuItem onClick={findLayout}>Update Layout from Server</MenuItem> */}
        <MenuItem
          onClick={() => {
            setShowLayoutSettings(true);
          }}
        >
          Layout Settings
        </MenuItem>
        {/* <MenuItem onClick={clearLayout}>Clear Layout</MenuItem>
        <MenuItem onClick={updateLayout}>Update Layout from Client</MenuItem>
        <MenuItem onClick={createSample}>Create Sample Design</MenuItem>
        <Divider /> */}
        <MenuItem
          onClick={() => {
            setLeftPanel("layout-helper");
          }}
        >
          Open Layout Helper
        </MenuItem>
      </Menu>
      <LayoutSettings showLayoutSettings={showLayoutSettings} setShowLayoutSettings={setShowLayoutSettings} props={layoutProps} setProps={setLayoutProps} />
    </>
  );
}

function LayoutSettings({ props, setProps, showLayoutSettings, setShowLayoutSettings }) {
  const [val1, setVal1] = useState(props.overlapPercThreshold);
  const [val2, setVal2] = useState(props.seriesVariance1);

  function update() {
    setProps({
      OVERLAP_PERC_THRESHOLD: parseFloat(val1),
      SERIES_VARIANCE_1: parseFloat(val2),
    });
  }

  return (
    <Dialog open={showLayoutSettings} onClose={() => setShowLayoutSettings(false)}>
      <DialogContent
        style={{
          padding: 20,
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          alignItems: "stretch",
          gap: 10,
        }}
      >
        <TextField
          label="OVERLAP_PERC_THRESHOLD"
          value={val1}
          onChange={(e) => {
            setVal1(e.target.value);
          }}
        ></TextField>
        <TextField
          label="SERIES_VARIANCE_1"
          value={val2}
          onChange={(e) => {
            setVal2(e.target.value);
          }}
        ></TextField>
        <Button
          variant="contained"
          style={{
            textTransform: "none",
          }}
          onClick={() => {
            update();
            setShowLayoutSettings(false);
          }}
        >
          Save
        </Button>
      </DialogContent>
    </Dialog>
  );
}
export function randomColor() {
  // use a random hue and fixed saturation and lightness
  const hue = Math.floor(Math.random() * 360);
  const saturation = 80;
  const lightness = 50;
  return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
}
