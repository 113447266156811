// @ts-nocheck
import { Box, Button, ClickAwayListener, Dialog, IconButton, Menu, MenuItem, Popper, Select, Slider, TextField, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useSVG } from "../SVGContext";
import { getCoordsInSvg, toDegree, toRadians } from "../utils/utils";
import { useEffect, useRef, useState } from "react";
import { ColorSquare, ColorSquareStyle } from "../ColorSquare";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import { Icon } from "@material-ui/core";
import LineStyleIcon from "@mui/icons-material/LineStyle";
import Crop from "@mui/icons-material/Crop";
import ResizeMenu from "../ResizeMenu";
import { BGDEF, ROOT_GROUP_ID } from "../values/constants";
import AspectRatio from "@mui/joy/AspectRatio";
import Grid4x4Icon from "@mui/icons-material/Grid4x4";
import PanToolIcon from "@mui/icons-material/PanTool";
import NavigationIcon from "@mui/icons-material/Navigation";
import { deleteDesignServer, req, updateDesignServer } from "../utils/ServerUtils";
import DeleteIcon from "@mui/icons-material/Delete";
import { BACKGROUND_DEF_ID, BACKGROUND_DEF_SUFFIX } from "../library/BackgroundColorPanel";
import SVGDef from "../views/SvgDefView";
import MySketchPicker from "../MySketchPicker";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { FONT_PAIRS } from "../values/font-pairings";
import { generateHTML } from "@tiptap/core";
import { EditorExtensions } from "../views/Tiptap";
import { ResponsiveButton } from "./ResponsiveButton";
import { RandomizeColors } from "./ColorRandomizer";

function newPage(width, height) {
  const bg = {
    type: "rectangle",
    id: "background",
    x: 0,
    y: 0,
    width,
    height,
    fill: "white",
    stroke: "transparent",
    id: "background",
    group: ROOT_GROUP_ID,
  };
  return {
    groups: {
      [ROOT_GROUP_ID]: { id: ROOT_GROUP_ID, children: ["background"] },
    },
    items: {},
  };
}
export const TopbarMain = ({}) => {
  return (
    <>
      <Resize />
      <FillColor />
      {/* <CropToContent /> */}
      {/* <RandomizeFonts /> */}
      {/* <ToggleSnapping /> */}
      <DragOrDraw />

      <DeletePage />
      <MoreSettings />
    </>
  );
};
function MoreSettings() {
  const { userInfo } = useSVG();
  const { design, setDesign } = useSVG();
  const [anchorEl, setAnchorEl] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [stringified, setStringified] = useState("");

  useEffect(() => {
    showDialog && setStringified(JSON.stringify(design, null, 2));
  }, [showDialog]);

  if (!userInfo?.permissions.includes("can_ai_generate")) {
    return;
  }

  return (
    <>
      <IconButton
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
        }}
      >
        <MoreVertIcon />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => {
          setShowDialog(true);
          setAnchorEl(null);
        }}
      >
        <MenuItem>JSON editor</MenuItem>
      </Menu>

      <Dialog open={showDialog} onClose={() => setShowDialog(false)} style={{}}>
        <div
          style={{
            width: 600,
            height: 600,
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
            justifyContent: "start",
            padding: 20,
          }}
        >
          <TextField
            fullWidth
            multiline
            rows={20}
            value={stringified}
            style={{
              flexGrow: 1,
            }}
            onChange={(e) => {
              setStringified(e.target.value);
            }}
          />
          <Button
            variant="contained"
            onClick={(e) => {
              setShowDialog(false);
              try {
                let d = JSON.parse(stringified);
                setDesign(d);
              } catch (e) {
                console.error(e);
              }
            }}
          >
            Save Design
          </Button>
        </div>
      </Dialog>
    </>
  );
}
function DeletePage({}) {
  const { design, setDesign, selectedPage, setSelectedPage } = useSVG();
  const [anchorEl, setAnchorEl] = useState(null);
  return (
    <>
      <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
        <DeleteIcon
          style={{
            color: "#bb1818",
          }}
        />
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
        <MenuItem
          onClick={() => {
            design.pages.splice(selectedPage, 1);
            setDesign({ ...design });
            setSelectedPage(Math.min(selectedPage, design.pages.length - 1));
            setAnchorEl(null);

            updateDesignServer(design).then((res) => {
              setDesign({ ...design, version: res.version });
            });
          }}
        >
          Delete Page
        </MenuItem>
        <MenuItem
          onClick={() => {
            const endPoint = design.isBlock ? "/blocks" : "/designs";
            req(`${endPoint}/${design.id}`, "DELETE").then((res) => {
              if (res.status === 200) {
                window.location.href = "/designs";
              }
            });
          }}
        >
          Delete Entire Design
        </MenuItem>
      </Menu>
    </>
  );
}
function DragOrDraw() {
  const { isPointerOrPan, setIsPointerOrPan } = useSVG();
  return (
    <ToggleButtonGroup
      value={isPointerOrPan}
      exclusive
      onChange={(e, v) => {
        setIsPointerOrPan(v);
      }}
      aria-label="text alignment"
    >
      <ToggleButton value="pointer" aria-label="left aligned">
        <NavigationIcon />
      </ToggleButton>
      <ToggleButton value="pan" aria-label="centered">
        <PanToolIcon />
      </ToggleButton>
    </ToggleButtonGroup>
  );
}
function ToggleSnapping({}) {
  const { shouldSnap, setShouldSnap } = useSVG();
  return (
    <IconButton onClick={() => setShouldSnap(!shouldSnap)}>
      <Grid4x4Icon />
    </IconButton>
  );
}

export function FillColor({}) {
  let { setLeftPanel, items, selectedIds } = useSVG();
  const [background, setBackground] = useState("white");
  const [fill, setFill] = useState("white");

  useEffect(() => {
    if (!items) {
      setFill("white");
      return;
    }
    const itemId = items[selectedIds[0]] || "background";
    const bgDef = items[itemId]?.defs?.find((def) => def.id === itemId + "-" + BACKGROUND_DEF_SUFFIX);
    setBackground(bgDef);
    setFill(items[itemId]?.fill);
  }, [selectedIds]);

  const w = 35;
  return (
    <svg
      style={{
        height: w + 4,
        width: w + 4,
        minWidth: w + 4,
      }}
      onClick={() => {
        setLeftPanel("colors");
      }}
    >
      {background && (
        <defs>
          <SVGDef def={background} />
        </defs>
      )}
      <rect
        x={2}
        y={2}
        width={w}
        height={w}
        fill={fill}
        stroke="black"
        strokeWidth={1}
        rx={5}
        ry={5}
        style={{
          height: w,
          width: w,
        }}
      />
    </svg>
  );
}
function CropToContent({}) {
  let { items, changeDimensions, svgRef } = useSVG();
  return (
    <IconButton onClick={crop}>
      <Crop />
    </IconButton>
  );
  function crop() {
    let minX = Infinity,
      minY = Infinity,
      maxX = -Infinity,
      maxY = -Infinity;
    for (let id in items) {
      if (id == "background") continue;
      const it = items[id];
      let box = document.getElementById(id).getBoundingClientRect();
      let p1 = {
          x: box.left,
          y: box.top,
        },
        p2 = {
          x: box.left + box.width,
          y: box.top + box.height,
        };
      p1 = getCoordsInSvg(svgRef, p1);
      p2 = getCoordsInSvg(svgRef, p2);
      minX = Math.min(minX, p1.x);
      minY = Math.min(minY, p1.y);
      maxX = Math.max(maxX, p2.x);
      maxY = Math.max(maxY, p2.y);
      console.log(id, box);
    }
    let width = maxX - minX;
    let height = maxY - minY;
    let x = minX;
    let y = minY;
    const viewbox = { x, y, width, height };
    changeDimensions({ viewbox, width, height });
    // change background
  }
}

function RandomizeFonts() {
  let { items, update, selectedIds, design, addGoogleFont } = useSVG();
  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <>
      <Button
        onClick={randomize}
        size="small"
        variant="outlined"
        style={{
          textTransform: "none",
        }}
      >
        <ShowChartIcon style={{ color: "#333", marginRight: 10 }} /> Change Fonts
      </Button>
    </>
  );

  function randomize() {
    const id_spec = design.pages[0].id_spec;
    if (!id_spec) {
      console.error("No id spec");
      return;
    }
    const pairing = FONT_PAIRS[Math.floor(Math.random() * FONT_PAIRS.length)];
    addGoogleFont(pairing[0]);
    addGoogleFont(pairing[1]);
    for (let key of ["title", "subtitle"]) {
      if (id_spec.header && id_spec.header[key]) {
        useFont(items, id_spec.header[key], pairing[0]);
      }
    }
    for (let key of ["title", "subtitle", "body"]) {
      for (let i = 0; i < id_spec.blocks.length; i++) {
        useFont(items, id_spec.blocks[i][key], pairing[1]);
      }
    }
    update({ items: { ...items } });
  }
}

function useFont(items, id, font) {
  if (id && items[id] && items[id]["proseMirrorData"]) {
    changeProseMirrorFont(items[id].proseMirrorData, font);
    items[id].html = generateHTML(items[id].proseMirrorData, EditorExtensions);
  }
}

function changeProseMirrorFont(node, font) {
  if (node.marks) {
    for (let mark of node.marks) {
      if (mark.type === "textStyle") {
        mark.attrs.fontFamily = font;
      }
    }
  }
  if (node.content) {
    for (let child of node.content) {
      changeProseMirrorFont(child, font);
    }
  }
}

function Resize({}) {
  return (
    <>
      <ResizeMenu resizeItems={resizeOptions} buttonLabel="Resize" getLabel={(item) => <ResizeMenuItemLabel item={item} />} menuTitle="Resize Options" />
    </>
  );
}

const resizeOptions = [
  { label: "Custom" },
  { label: "Instagram Reel/Story", h: 1080, w: 1920 },
  { label: "Instagram Post", h: 1080, w: 1080 },
  { label: "Facebook Post", h: 940, w: 788 },
  { label: "Facebook Cover", h: 851, w: 315 },
  { label: "Facebook Story", h: 1080, w: 1920 },
  { label: "YouTube Thumbnail", h: 1280, w: 720 },
  { label: "YouTube Banner", h: 2560, w: 1440 },
  { label: "LinkedIn Background Photo", h: 1584, w: 396 },
  { label: "LinkedIn Post", h: 1200, w: 1200 },
  { label: "Pinteest Pin", h: 1000, w: 1500 },
  { label: "Twitter Post", h: 1600, w: 900 },

  {
    label: "Print Media",
    items: [{ label: "Poster" }, { label: "A4" }],
  },
];
