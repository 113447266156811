// @ts-nocheck
import { memo, useRef, useState } from "react";
import { useSVG } from "./SVGContext";
import SVGDef from "./views/SvgDefView";
import { getCoordsInSvg } from "./utils/utils";
import { SvgGrid } from "./SvgGrid";
import { ROOT_GROUP_ID } from "./values/constants";
import { SVGGroupView } from "./views/SVGGroupView";
import DivWithEvents from "./DivWithEvents";
import { MultiselectBox } from "./MultiselectBox";
import { Topbar } from "./Topbar";
import { MousePosition } from "./MousePosition";
import { CircularProgress } from "@mui/material";
import { FRAME_DEFS } from "./values/constants";
import { ARROW_MARKER_DEFS } from "./values/constants";
import { ZoomSlider } from "./ZoomSlider";
import FadedRects from "./FadedRects";
import { CropPopper } from "./views/CropPopper";
import { ShapeClipPaths } from "./ShapeClipPaths";
import { useParams } from "react-router-dom";
import FakeScrollbar from "./FakeScrollbar";

const SVGContainer = ({ onKeyDown, onKeyUp }) => {
  const z = useSVG();

  const { notification, design, viewBox, tool, items, groups, defs, fill, stroke, selectedIds, resizeRect, selectedCorners, outline, multipleOutlines, mouseReceivers, svgDecorators, multiSelectBox, croppingItemId, clearTopbarSelectedButtons, fullscreenLoading, pages, selectedPage, setMousePos, snapLines, width, height, svgRef, cursor, setSelectedIds } = z;

  const fakeEvent = (cb, event, items) => {
    let ncb = cb + "New";
    // console.log(cb, tool?.name);
    if (!tool) {
      console.error("tool is not defined");
      return;
    }
    if (!tool[ncb] && !tool[cb]) return;

    let x, y;
    if (event.touches && event.touches.length == 1 && event.touches[0]) {
      x = event.touches[0].clientX;
      y = event.touches[0].clientY;
    } else {
      x = event.clientX;
      y = event.clientY;
    }

    let xy = getCoordsInSvg(svgRef, { x, y });
    let target = event.target;
    try {
      tool[ncb] && tool[ncb]({ event, xy, target, items, groups, defs, selectedIds, fill, stroke, resizeRect, cursor, multiSelectBox, selectedCorners, viewBox, z, history: z.history, outline });
    } catch (e) {
      console.error(e);
    }
  };

  const onOverlayMouseDown = (event) => {
    fakeEvent("onMouseDown", event, items);
  };
  const onOverlayMouseMove = (event) => {
    // mouseCoords = getCoordinatesInSVG(svgRef, viewBox, width, height, event);
    // setMousePos(
    //   getCoordsInSvg(svgRef, {
    //     x: event.nativeEvent.clientX,
    //     y: event.nativeEvent.clientY,
    //   }),
    // );
    fakeEvent("onMouseMove", event, items);
  };
  const onOverlayMouseUp = (event) => {
    fakeEvent("onMouseUp", event, items);
  };
  const onOverlayMouseOut = (event) => {
    fakeEvent("onMouseOut", event, items);
  };
  const onOverlayTouchMove = (event) => {
    fakeEvent("onMouseMove", event, items);
  };
  const onOverlayTouchUp = (event) => {
    fakeEvent("onMouseUp", event, items);
  };
  const onOverlayTouchDown = (event) => {
    fakeEvent("onMouseDown", event, items);
  };
  const eventHandlers = {
    onMouseDown: onOverlayMouseDown,
    onMouseMove: onOverlayMouseMove,
    onMouseUp: onOverlayMouseUp,
    onMouseOut: onOverlayMouseOut,
    onTouchStart: onOverlayTouchDown,
    onTouchMove: onOverlayTouchMove,
    onTouchEnd: onOverlayTouchUp,
    onTouchCancel: onOverlayTouchUp,
    onKeyDown: onKeyDown,
    onKeyUp: onKeyUp,
  };

  return (
    <div
      style={{
        position: "relative",
        flexGrow: 1,

        display: "flex",
        flexDirection: "column",
        justifyContent: "stretch",
        alignItems: "stretch",
        overflow: "hidden",
      }}
    >
      <div className="topbar">
        <Topbar selectedIds={selectedIds} />
      </div>
      <div
        style={{
          overflow: "hidden",
          flexGrow: 1,
          touchAction: "none", // See DivWithEvents for explanation
        }}
      >
        <DivWithEvents
          className="SVGContainer"
          style={{
            cursor,
            height: "100%",
          }}
          {...eventHandlers}
        >
          <svg
            key="svg1"
            xmlns="http://www.w3.org/2000/svg"
            xlinkHref="http://www.w3.org/1999/xlink"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            tabIndex="0"
            className="Board"
            ref={svgRef}
            viewBox={`${viewBox.x} ${viewBox.y} ${viewBox.width} ${viewBox.height}`}
            style={{
              opacity: croppingItemId ? 0.2 : 1,
            }}
            height="100%"
            width="100%"
          >
            <g>
              <defs>
                {[...FRAME_DEFS, ...ARROW_MARKER_DEFS].map((def, index) => (
                  <SVGDef key={index} def={def} />
                ))}
                {defs.map((def, index) => (
                  <SVGDef key={index} def={def} />
                ))}
              </defs>
              <SVGGroupView group={groups[ROOT_GROUP_ID]} />
            </g>

            {
              <g id="overlay">
                <FadedRects />

                <SVGGroupView group={groups[ROOT_GROUP_ID]} overlay={true} />

                {outline && <path id="hover-outline" d={outline.d} fill="none" stroke={outline.stroke} strokeWidth="2" transform={outline.transform} />}
                <MultiselectBox multiSelectBox={multiSelectBox} selectedIds={selectedIds} />
                {multipleOutlines && <path id="multiple-outlines" d={multipleOutlines.d} fill="none" stroke={"blue"} strokeWidth="2" />}

                {snapLines.map((line, i) => (
                  <line key={i} x1={line.p1.x} y1={line.p1.y} x2={line.p2.x} y2={line.p2.y} stroke="red" strokeWidth="1" />
                ))}
              </g>
            }
          </svg>
          <svg
            id="overlay"
            height="100%"
            width="100%"
            viewBox={`${viewBox.x} ${viewBox.y} ${viewBox.width} ${viewBox.height}`}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              display: croppingItemId ? "block" : "none",
            }}
          >
            <SVGGroupView group={groups[ROOT_GROUP_ID]} overlay={true} />
            <MultiselectBox multiSelectBox={multiSelectBox} selectedIds={selectedIds} />
          </svg>
          <FakeScrollbar horiz={false} />
          <FakeScrollbar horiz={true} />

          {fullscreenLoading && (
            <div className="loading-overlay">
              <CircularProgress />
            </div>
          )}
        </DivWithEvents>
      </div>
      <div
        classname="editorbottombar"
        style={{
          position: "static",
          bottom: 0,
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "end",
          alignItems: "center",
          padding: 5,
        }}
      >
        {notification}
        <ZoomSlider />
      </div>
      {/* <MousePosition /> */}
    </div>
  );
};

export default SVGContainer;
