import { useSVG } from "../SVGContext";
import { ResponsiveButton } from "./ResponsiveButton";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import { getHue, shiftHues } from "./colors";
import { generateHTML } from "@tiptap/core";
import { EditorExtensions } from "../views/Tiptap";
import { BACKGROUND_DEF_SUFFIX } from "../library/BackgroundColorPanel";
import { extractTextColorFromPM, setTextColorInPM } from "../ProseUtils";

export function changeHues(hueDelta, items, groups, update) {
  // gather fills and strokes
  const fills = Object.keys(items).map((it) => items[it].fill),
    strokes = Object.keys(items).map((it) => items[it].stroke);

  // gather gradient colors
  let gradientColors = [];
  for (let id in items) {
    if (items[id].defs) {
      const bg = items[id].defs.find((def) => def.id === id + "-" + BACKGROUND_DEF_SUFFIX);
      if (bg) gradientColors = [...gradientColors, ...bg.colors];
    }
  }

  // gather font colors
  let fontColors = [];
  for (let id in items) {
    if (items[id].proseMirrorData) {
      const clr = extractTextColorFromPM(items[id].proseMirrorData);
      if (clr) fontColors.push(clr);
    }
  }

  // combine colors & filter some out
  const colors = Array.from(new Set([...fills, ...strokes, ...gradientColors, ...fontColors].filter((it) => it && it != "transparent" && !it.startsWith("url"))));

  hueDelta = hueDelta || Math.random() * 360 - getHue(colors[0]);

  const newColors = shiftHues(colors, hueDelta);
  const map = {
    transparent: "transparent",
  };
  for (let i = 0; i < colors.length; i++) {
    map[colors[i]] = newColors[i];
  }

  changeColors(items, groups, update, map);
}

export function changeColors(items, groups, update, map) {
  for (let id in items) {
    let item = items[id];
    if (map[item.fill]) item.fill = map[item.fill];
    if (map[item.stroke]) item.stroke = map[item.stroke];
    const bgDef = item.defs?.find((def) => def.id === id + "-" + BACKGROUND_DEF_SUFFIX);
    if (bgDef) {
      bgDef.colors = bgDef.colors.map((color) => (map[color] ? map[color] : color));
    }
    if (item.proseMirrorData) {
      const clr = extractTextColorFromPM(item.proseMirrorData);
      if (clr && map[clr]) {
        setTextColorInPM(item.proseMirrorData, map[clr]);
        item.html = generateHTML(item.proseMirrorData, EditorExtensions);
      }
    }
  }
  update({ items: { ...items } });
}

export function RandomizeColors({}) {
  let { items, groups, update } = useSVG();

  return (
    <>
      <ResponsiveButton icon={<TipsAndUpdatesIcon />} onClick={randomize} label="Randomize" />
    </>
  );

  function randomize() {
    changeHues(0, items, groups, update);
  }
}
