import { useEffect, useState } from "react";
import { useSVG } from "../SVGContext";
import { TopBarNumber } from "./TopbarCommons";
import { extractLineHeightFromPM, setLineHeightInPM, updateSelectedTextItems } from "../ProseUtils";
import FormatLineSpacingIcon from "@mui/icons-material/FormatLineSpacing";

export function TopbarLineHeight() {
  let { editor, items, selectedIds, update, editorState } = useSVG();
  const [h, setH] = useState(1);

  useEffect(() => {
    setH(which());
  }, [items, selectedIds]);

  function which() {
    if (selectedIds.length === 1 && items[selectedIds[0]].proseMirrorData) {
      return extractLineHeightFromPM(items[selectedIds[0]].proseMirrorData);
    } else if (selectedIds.length > 1) {
      return 1;
    } else {
      return +editorState.lineHeight;
    }
  }

  function onLineHeightChange(height) {
    if (isNaN(height)) return;

    if (!editor.isFocused) {
      updateSelectedTextItems({ selectedIds, items, update }, (it) => {
        setLineHeightInPM(it.proseMirrorData, height);
      });
    } else {
      editor.chain().focus().setLineHeight(height).run();
      editor.view.dispatch(editor.state.tr);
    }
  }

  return <TopBarNumber name="Line Height" min={Math.max(h - 0.5, 0.8)} max={Math.min(h + 0.5, 2)} step={0.01} value={h} icon={<FormatLineSpacingIcon />} onChange={(e) => onLineHeightChange(+e.target.value)} />;
}
