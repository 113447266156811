// @ts-nocheck
import { Box, IconButton, InputLabel, MenuItem, Select } from "@mui/material";
import { useSVG } from "../SVGContext";
import { MpImageView } from "./MpImageView";
import { ShapeView } from "./ShapeView";
import { TextView } from "./TextView";
import { RotateBtn } from "./RotateBtn";
import { getTransformStr } from "../utils/transformUtils";
import { ClipPath, Shape } from "./Shape";
import { EditBtn } from "./EditBtn";
import { SteppedConnector } from "./SteppedConnector";
import SVGDef from "./SvgDefView";
import { VectorView } from "./VectorView";
import { useEffect, useState } from "react";

const SVGItemView = ({ item, overlay }) => {
  const { svgRef, croppingItemId, selectedIds } = useSVG();

  if (!item) return null;
  if (overlay) {
    if (!selectedIds.includes(item.id)) return;
    if (croppingItemId && item.type !== "mpimage") return;
  }

  if (!item.mpTransformStr) return <SVGItemViewWithoutGroup item={item} overlay={overlay} />;

  return (
    <g transform={item.mpTransformStr}>
      <SVGItemViewWithoutGroup item={item} overlay={overlay} />
      <Commons item={item} overlay={overlay} />
    </g>
  );
};

const Commons = ({ item, overlay }) => {
  if (!overlay) return null;
  return <>{/* <RotateBtn item={item} /> */}</>;
};

const SVGItemViewWithoutGroup = ({ item, overlay }) => {
  const { croppingItemId, update, items } = useSVG();

  if (!item || item.hidden) return null;
  if (overlay && croppingItemId && item.type !== "mpimage") return;

  let tagX, tagY;
  if (item.spec) {
    tagX = item.x || item.box.x;
    tagY = item.y || item.box.y;
    if (item.spec?.type == "body") {
      tagX += item.width;
    }
    if (item.spec?.type == "position") {
      tagY += item.height;
      tagX -= 20;
    }
  }
  const tag = item.spec && (
    <>
      <foreignObject x={tagX} y={tagY} height={60} width={90}>
        <div
          xmlns="http://www.w3.org/1999/xhtml"
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 5,
          }}
        >
          <div
            style={{
              backgroundColor: item.spec?.color,
            }}
          >
            {item.spec?.bid}
          </div>
          <Select
            size="small"
            className="nopadding"
            value={item.spec?.type}
            onChange={(e) => {
              item.spec.type = e.target.value;
              update({ items });
            }}
            style={{
              backgroundColor: item.spec?.color,
            }}
          >
            {["title", "body", "subtitle", "position", "unk", "vector", "image", "rectangle"].map((t, i) => (
              <MenuItem key={t} fill="#000" fontSize={24} value={t}>
                {t == "position" ? "pos" : t}
              </MenuItem>
            ))}
          </Select>
        </div>
      </foreignObject>
    </>
  );

  const defs = <defs>{item.defs?.map((def, i) => <SVGDef key={i} def={def} />)}</defs>;

  const transforms = gatherTransforms(item);
  if (transforms) {
    // <svg> doesn't rotate itself when I pass this as a transform to it, so I'm wrapping it & every other type of item in a <g> tag
    return (
      <g transform={transforms}>
        <SVGItemViewWithoutGroup item={{ ...item, ignoreTransforms: true }} />
      </g>
    );
  }

  function getSimpleView() {
    if (!overlay) {
      // don't do ...item in ShapeView and for path. render only svg attributes
      if (["rectangle", "circle", "ellipse", "triangle", "star", "pentagon", "hexagon", "semi-circle", "quarter-circle", "straight"].includes(item.type)) return <ShapeView item={item} overlay={overlay} />;

      if (item.type == "path") return <path {...item} overlay={overlay} />;
      if (item.type == "shape") return <Shape item={item} overlay={overlay} />;
      if (item.type == "style") return <style>{item.content}</style>;
      if (item.type == "stepped") return <SteppedConnector item={item} overlay={overlay} />;
    }
    if (item.type == "mpimage") return <MpImageView item={item} overlay={overlay} />;
    if (item.proseMirrorData) return <TextView item={item} item={item} overlay={overlay} />;
    if (item.type == "vector" || item.type == "icon") return <VectorView item={item} overlay={overlay} />;
  }
  return (
    <g>
      {defs}
      {getSimpleView()}
      {tag}
    </g>
  );
};

export default SVGItemView;

export function gatherTransforms(item) {
  if (item.ignoreTransforms) return null;
  const transforms = [];
  if (item.transform) {
    // transforms.push(item.transform);
  }
  if (item.rotateDeg) transforms.push(`rotate(${item.rotateDeg} ${item.x + item.width / 2} ${item.y + item.height / 2})`);

  // note: this is for editorg. venn doesn't have this, I think. Idk how they do scaling. we just modify width/height etc
  if (item.scaleX || item.scaleY) {
    transforms.push(`scale(${item.scaleX || 1} ${item.scaleY || 1})`);
  }

  return transforms.join(" ");
}
