// @ts-nocheck

import { useRef, useState } from "react";
import MySketchPicker from "./MySketchPicker";
import { Button, Checkbox, Popper } from "@mui/material";

export const ColorSquareStyle = {
  width: 35,
  height: 35,
  borderRadius: 2,
  border: "solid 1px #555",
};

export function ColorSquare({ color, isStroke = false, onChange, allowChangeAll, allowRotateAll }) {
  const [showPicker, setShowPicker] = useState(false);
  const colorRef = useRef(null);
  const [shouldChangeAll, setShouldChangeAll] = useState(false);
  const [shouldRotateAll, setShouldRotateAll] = useState(false);

  const style = { ...ColorSquareStyle };
  if (isStroke) {
    style.border = "solid 1px " + color;
    style.boxShadow = "0 0 0 1px #555";
    style.backgroundColor = "transparent";
  }

  return (
    <div>
      <div
        ref={colorRef}
        style={{ ...style, backgroundColor: color }}
        onClick={() => {
          setShowPicker(true);
        }}
      ></div>
      <Popper
        open={showPicker}
        anchorEl={colorRef.current}
        placement="bottom-start"
        style={{
          zIndex: 1000,
          backgroundColor: "white",
          boxShadow: "rgba(0, 0, 0, 0.15) 0px 0px 0px 1px, rgba(0, 0, 0, 0.15) 0px 8px 16px",
          border: "1px solid #ccc",
          borderRadius: 4,
        }}
      >
        <MySketchPicker
          setShowPicker={setShowPicker}
          color={color}
          onChangeComplete={(clr) => {
            onChange(clr, {
              changeAll: shouldChangeAll,
              rotateAll: shouldRotateAll,
            });
          }}
        />
        {allowChangeAll && (
          <>
            <Checkbox
              checked={shouldChangeAll}
              onChange={(e) => {
                setShouldChangeAll(e.target.checked);
                e.stopPropagation();
                e.preventDefault();
              }}
            />
            <span>Change All</span>
          </>
        )}
        {allowRotateAll && (
          <>
            <Checkbox
              checked={shouldRotateAll}
              onChange={(e) => {
                setShouldRotateAll(e.target.checked);
                e.stopPropagation();
                e.preventDefault();
              }}
            />
            <span>Rotate All</span>
          </>
        )}
      </Popper>
    </div>
  );
}
